<template>
  <AppLayout>
    <template v-slot:appContent>
      <section class="users-list-wrapper">
        <div class="d-flex justify-content-between align-items-center pt-2 pt-md-2 pt-xl-0 ">
          <h4 class="">Blog Categories/List</h4>
          <div>
            <router-link :to="can('blog-category-create') ? {name:'appBlogCategoryCreate'} : '#'">
              <span :title="can('blog-category-create') ? 'Create' : 'Create Forbidden'"
                    class="glow d-flex align-items-center">
                <i class='bx bx-plus bx-flip-vertical'></i>&nbsp;Create</span>
            </router-link>
          </div>
        </div>
        <div class="users-list-filter px-1 my-2 py-2 border rounded">
          <div>
            <div class="row align-items-center ">
              <div class="col-12 col-sm-12 col-md-2">
                <label>Show Per Page</label>
                <VueMultiselect v-model="selectedPagination" class="" :options="paginationList" :close-on-select="true"
                                label="name" track-by="name" :show-labels="false"/>
              </div>

              <div class="col-12 col-sm-12 col-md-3">
                <label>Status</label>
                <VueMultiselect :allowEmpty="false" v-model="selectedStatus"
                                :options="statusList"
                                :close-on-select="true"
                                placeholder="Select blog category status" label="name" track-by="name" :show-labels="false"/>
              </div>

              <div class="col-12 col-sm-12 col-md-5">
                <label></label>
                <input v-model="getBlogCategoriesParams.name" class="form-control search-blog-input-element"
                       type="text"
                       placeholder="Search a blog category by name">
              </div>

              <div class="col-12 col-sm-12 col-md-2">
                <label></label>
                <button type="reset" class="btn btn-primary btn-block glow users-list-clear mb-0"
                        @click="applyBlogCategoryFilter(null)">Filter
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="users-list-table">
          <div class="card">
            <div class="card-body">
              <div class="table-responsive">
                <table id="users-list-datatable" class="table">
                  <thead>
                  <tr>
                    <th class="position-relative" :style="{width:'5%'}">SL</th>
                    <th class="position-relative" :style="{width:'60%'}">NAME</th>
                    <th class="position-relative" :style="{width:'25%'}">STATUS</th>
                    <th class="position-relative" :style="{width:'10%'}">Actions</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(blogCategory, index) in blogCategories" :key="index">
                    <td>{{ index + 1 }}</td>
                    <td>{{ blogCategory.name }}</td>
                    <td>
                      <button class="btn"
                              :disabled="!can('blog-category-update')"
                              :title="can('blog-category-update') ? `${blogCategory.status} | Click to update` : `${blogCategory.status} | ${unauthorized}`"
                              data-toggle="modal"
                              data-target="#blogCategoryStatusUpdateAlertModal"
                              @click="this.modelForUpdating = {modelId: blogCategory.id, existingData: {status: blogCategory.status}}">
                        <span class="badge"
                              :class="blogCategory.status === 'Active' ? 'badge-light-success' : 'badge-light-danger'">{{
                            blogCategory.status
                          }}</span>
                      </button>
                    </td>

                    <td>
                      <div class="d-flex align-items-top justify-content-between">
                        <div>
                          <div class="cursor-pointer" :style="{height:'18px'}"
                               @click="getSingleBlogCategory(blogCategory.id)" data-toggle="modal"
                               data-target="#blogCategoryDetails">
                            <i class='bx bx-show'></i>
                          </div>
                        </div>
                        <div class="dropdown py-0 px-0 view-edit-delete-icon" :style="{height:'18px'}">
                                                    <span type="button" class="px-0 py-0" data-toggle="dropdown"
                                                          aria-haspopup="true" aria-expanded="false">
                                                        <i class='bx bx-dots-vertical-rounded h-100'></i>
                                                    </span>
                          <div class="dropdown-menu" style="min-height: 100px">

                            <button :disabled="!can('blog-category-update')" @click="getSingleBlogCategory(blogCategory.id)" data-toggle="modal"
                                    :title="can('blog-category-update') ? 'Update' : 'Update Forbidden'"
                                    data-target="#updateBlogCategoryModal" class="dropdown-item">Update
                            </button>
                            <button :disabled="!can('blog-category-delete')" @click="modelIdForDelete = blogCategory.id" data-toggle="modal"
                                    :title="can('blog-category-delete') ? 'Delete' : 'Delete Forbidden'"
                                    data-target="#deleteBlogCategoryModal" class="dropdown-item">Delete
                            </button>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                  </tbody>
                </table>
                <ListPagination position="right" :pagination-data="paginateLinks"
                                @getClickedPage="applyBlogCategoryFilter"/>
              </div>
            </div>
          </div>
        </div>
        <BlogCategoryModal :blog-category="this.blogCategory"/>
        <BlogCategoryUpdateModal :blog-category="this.blogCategory" :blog-category-list="blogCategoryList"/>
        <BlogCategoryStatusUpdateAlertModal :model-for-updating="modelForUpdating" model-name="blogCategory"
                                           modal-name="blogCategoryStatusUpdateAlertModal"
                                           @confirmModelUpdating="blogCategoryStatusUpdating"/>
        <BlogCategoryDeleteAlertModal modal-name="deleteBlogCategoryModal" model-name="blogCategory" :model-id="modelIdForDelete"
                                         @confirmModelDeletion="onConfirmModelDeletion"/>
      </section>
    </template>
  </AppLayout>
</template>

<script>
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import Loader from "@/components/backEnd/mixins/Loader";
import Authorization from "@/components/backEnd/mixins/Authorization";
import AppLayout from "@/layouts/backEnd/AppLayout";
import BlogCategoryUpdateModal from "@/views/backEnd/blogCategories/includes/BlogCategoryUpdateModal";
import BlogCategoryStatusUpdateAlertModal from "@/components/backEnd/modal/UpdateAlertModal";
import BlogCategoryDeleteAlertModal from "@/components/backEnd/modal/DeleteAlertModal";
import ListPagination from "@/components/backEnd/pagination/ListPagination";
import BlogCategoryModal from "@/views/backEnd/blogCategories/includes/BlogCategoryViewModal";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "BlogCategoryList",
  mixins: [ShowToastMessage, Loader, Authorization],
  components: {
    AppLayout,
    BlogCategoryUpdateModal,
    BlogCategoryStatusUpdateAlertModal,
    BlogCategoryDeleteAlertModal,
    ListPagination,
    BlogCategoryModal
  },

  data() {
    return {
      unauthorized: 'You are unauthorized to perform this action.',
      modelIdForDelete: '',
      blogCategory: {},

      modelForUpdating: {
        ModelId: '',
        existingData: {},
      },

      selectedPagination: {
        value: '',
        name: 'Default'
      },
      selectedStatus: {
        value: '',
        name: 'Any'
      },
      getBlogCategoriesParams: {
        name: '',
        status: '',
        // with_relation: ['blogs'],
        order_by_id: 'DESC',
        paginate: 1,
        pagination: '',
        page: '',
      },
      getSettingsParams: {
        type: ['default'],
        key: ['default_pagination'],
      },
    }
  },
  watch: {
    selectedPagination: function (pagination) {
      this.getBlogCategoriesParams.pagination = pagination.value;
    },
    selectedStatus: function (status) {
      this.getBlogCategoriesParams.status = status.value;
    },
  },
  computed: {
    ...mapGetters({
      paginateLinks: 'appBlogCategories/paginateLinks',
      blogCategories: 'appBlogCategories/blogCategories',
    }),
    paginationList() {
      return [
        {value: this.$store.getters['appSettings/settingDefaultPagination'].value, name: 'Default'},
        {value: 25, name: '25 Entries'},
        {value: 50, name: '50 Entries'},
        {value: 100, name: '100 Entries'}
      ];
    },
    statusList() {
      return [
        {
          value: '',
          name: 'Any'
        },
        {
          value: 0,
          name: 'Inactive'
        },
        {
          value: 1,
          name: 'Active'
        },
      ];
    },
    blogCategoryList() {
      let blogCategories = this.blogCategories ? this.blogCategories.map(blogCategory => {
        return {
          value: blogCategory.id,
          name: blogCategory.name,
        }
      }) : [];

      return [
        {
          value: '',
          name: 'None',
        },
        ...blogCategories
      ]
    },
  },
  methods: {
    ...mapActions({
      getBlogCategories: 'appBlogCategories/getBlogCategories',
      getSettings: 'appSettings/getSettings',
      putBlogCategoryOnList: 'appBlogCategories/putBlogCategoryOnList',
      deleteBlogCategoryOnList: 'appBlogCategories/deleteBlogCategoryOnList',
      resetBlogCategories: 'appBlogCategories/resetBlogCategories',
    }),

    async getBlogCategoryList() {
      await this.getBlogCategories(this.getBlogCategoriesParams).then((response) => {
        if (response && response.message && response.status !== 200) {
          this.showToastMessage(response);
        }
      })
    },

    async getSettingList() {
      await this.getSettings(this.getSettingsParams);
    },

    async applyBlogCategoryFilter(pageNumber) {
      this.getBlogCategoriesParams.page = pageNumber;
      await this.loader(true);
      await this.getBlogCategoryList();
      await this.loader(false);
    },

    async getSingleBlogCategory(id) {
      let index = this.blogCategories.findIndex(item => item.id === id);
      this.blogCategory = index !== -1 ? this.blogCategories[index] : {}
    },

    async blogCategoryStatusUpdating(blogCategoryStatusUpdating) {
      await this.blogCategoryStatusUpdatingOnList(blogCategoryStatusUpdating);
    },

    async blogCategoryStatusUpdatingOnList(blogCategoryStatusUpdating) {
      let dataObj = {
        id: blogCategoryStatusUpdating.modelForUpdating.modelId,
        data: {
          status: blogCategoryStatusUpdating.modelForUpdating.existingData.status === "Active" ? 0 : 1,
        }
      }

      this.putBlogCategoryOnList(dataObj).then(async (response) => {
        if (response.status === 200) {
          const toastObj = {
            message: "Blog Category Status Updated",
            type: 'success'
          };
          this.showToastMessage(toastObj);
          return;
        }
        this.showToastMessage(response);
      });
    },

    async deleteSingleBlogCategoryOnList(id) {
      this.deleteBlogCategoryOnList(id).then(async (response) => {
        if (response.status === 200 || response.status === 204) {
          this.showToastMessage({type: 'success', message: 'Blog Category deleted successfully'});
          return;
        }
        this.showToastMessage(response);
      });
    },

    async onConfirmModelDeletion(confirmModelDeletion) {
      await this.deleteSingleBlogCategoryOnList(confirmModelDeletion.modelId);
    },


  },
  async mounted() {
    this.loader(true);
    await this.getSettingList();
    await this.getBlogCategoryList();
    this.loader(false);
  },

  beforeUnmount() {
    this.resetBlogCategories();
  }
}
</script>

<style scoped>

</style>