<template>
  <div class="modal fade " id="updateBlogCategoryModal" tabindex="-1" role="dialog"
       aria-labelledby="updateBlogCategoryModal"
       aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable modal-lg">
      <div class="modal-content">
        <div class="modal-header bg-primary">
          <h5 class="modal-title white" id="">Blog Category Update</h5>
          <button @click="resetBlogCategory" type="button" class="close" data-dismiss="modal" aria-label="Close">
            <i class="bx bx-x"></i>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-12">
              <div class="form-group">
                <div class="">
                  <label>Blog Category Name</label>
                  <input v-model="putBlogCategoryData.name" type="text" class="form-control"
                         placeholder="Enter Blog Category Name" name="blogName">
                  <div class="text-danger" v-if="errors.name">{{ errors.name }}</div>
                </div>
              </div>
            </div>

          </div>
        </div>

        <div class="modal-footer border-0 py-1">
          <button @click="updateSingleBlogCategory" type="button" class="btn btn-primary" data-dismiss="modal">
            <i class="bx bx-x d-block d-sm-none"></i>
            <span class="d-none d-sm-block">Update Blog Category</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {mapActions} from "vuex";
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";

export default {
  name: "BlogCategoryUpdateModal",
  props: ['blogCategory', 'blogCategoryList'],
  mixins: [ShowToastMessage],
  data() {
    return {

      putBlogCategoryData: {
        name: '',
      },
      errors: {
        name: '',
      }
    }
  },
  computed: {

  },
  watch: {
    blogCategory(blogCategory) {
      this.putBlogCategoryData.name = blogCategory.name;

    },
  },
  methods: {
    ... mapActions({
      putBlogCategoryOnList: 'appBlogCategories/putBlogCategoryOnList',
    }),
    async updateSingleBlogCategory() {
      let dataObj = {
        id: this.blogCategory.id,
        data: this.putBlogCategoryData,
      };

      await this.putBlogCategoryOnList(dataObj).then(async (response) => {
        if (response.status === 200) {
          let toastObj = {
            message: 'Blog Category Updated Successfully',
            type: 'success',
          }
          this.showToastMessage(toastObj);
          this.resetBlogCategory();
          return;
        }

        this.errors.name = response?.errors?.name ? response.errors.name : '';

        if (response.message) {
          this.showToastMessage(response);
        }

      });
    },
    resetBlogCategory() {
      Object.assign(this.$data, this.$options.data.apply(this));
    },
  },
}
</script>

<style scoped>

</style>