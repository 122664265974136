<template>
  <div class="modal fade " id="blogCategoryDetails" tabindex="-1" role="dialog"
       aria-labelledby="blogCategoryDetailsModal"
       aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable modal-lg">
      <div class="modal-content">
        <div class="modal-header bg-primary">
          <h5 class="modal-title white" id="">Blog Category Details</h5>
          <button @click="resetBlogCategory" type="button" class="close" data-dismiss="modal" aria-label="Close">
            <i class="bx bx-x"></i>
          </button>
        </div>
        <div class="modal-body">
          <h3>{{ name }}</h3>
          <h5>status: <span class="badge" :class="status === 'Active' ? 'badge-success' : 'badge-danger'">{{ status }}</span></h5>
          <p>Slug: {{ slug }}</p>
        </div>

        <div class="modal-footer border-0 py-1">
          <button @click="resetBlogCategory" type="button" class="btn btn-primary" data-dismiss="modal">
            <i class="bx bx-x d-block d-sm-none"></i>
            <span class="d-none d-sm-block">Close</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "BlogCategoryViewModal",
  props: ['blogCategory'],
  data() {
    return {
      name: '',
      slug: '',
      status: '',
    }
  },
  watch: {
    blogCategory() {
      this.name = this.blogCategory && this.blogCategory.name ? this.blogCategory.name : '';
      this.slug = this.blogCategory && this.blogCategory.slug ? this.blogCategory.slug : '';
      this.status = this.blogCategory && this.blogCategory.status ? this.blogCategory.status : '';
    }

  },
  methods: {
    resetBlogCategory() {
      Object.assign(this.$data, this.$options.data.apply(this));
    },
  },
}
</script>

<style scoped>

</style>